import React from 'react';
import "./Login.css";
import { useState } from 'react';
import axios from "axios";
import bacimg from "../Assets/wee.jpg"
import { Link, useNavigate } from "react-router-dom"; // Change import to useNavigate

export const Login = ({ setLoginUser }) => {
  const navigate = useNavigate(); // Replace useHistory with useNavigate

  const [user, setUser] = useState({
    email: "",
    password: ""
  });


  const [loading, setLoading] = useState(false);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser({
      ...user,
      [name]: value,
    });
  };

  const login = () => {
    setLoading(true);
    axios.post("https://loginapi-xrud.onrender.com/login", user)
      .then((res) => {
        setLoading(false);
        // You can perform actions on successful login, such as redirecting to another page
        const { name } = res.data.user;

        // Set the login user with the name
        setLoginUser({ name });
        setLoginUser(res.data.user);
        navigate("/"); // Use navigate to redirect
      })
      .catch(error => {
        setLoading(false);
        if (error.response) {
          if (error.response.status === 401) {
            // Custom alert for password mismatch
            alert("Password didn't match");
          } else if (error.response.status === 404) {
            // Custom alert for user not found
            alert("User not registered");
          } else {
            // Generic error handling for other errors
            console.error('Login Error:', error);
            alert('An error occurred. Please try again later.');
          }
        } else {
          // Handle non-response errors
          console.error('Non-response error:', error);
          alert('An error occurred. Please try again later.');
        }
      });
  };

  return (
    <div className='login' style={{ backgroundImage:`url(${bacimg})`,backgroundRepeat:"no-repeat",backgroundSize:"cover" }}>
    <h1>Login</h1>
    <input type="text" name="email" value={user.email} onChange={handleChange} placeholder='Enter your Email' />
    <input type="password" name="password" value={user.password} onChange={handleChange} placeholder="Enter your Password" />
    <div onClick={login} className='button'>
      {loading ? "Logging in..." : "Login"}
    </div>

      <div className='or'>
        or
      </div>

      <Link to="/register" className='button'>
        Register
      </Link>
    </div>
  );
};
