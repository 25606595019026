import React, { useState } from "react";
import "./Register.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export const Register = () => {
  const navigate = useNavigate();

  const [user, setUser] = useState({
    name: "",
    email: "",
    password: "",
    reEnterpassword: "",
  });

  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser({
      ...user,
      [name]: value,
    });
  };

  const register = () => {
    const { name, email, password, reEnterpassword } = user;

    if (name && email && password && password === reEnterpassword) {
      setLoading(true);

      axios.post("https://loginapi-xrud.onrender.com/register", user)
        .then((res) => {
          setLoading(false);
          console.log(res.data);
          setSuccessMessage("Registration successful! Redirecting to login...");
          setTimeout(() => {
            navigate("/login"); // Use navigate instead of history.push
          }, 2000);
        })
        .catch((error) => {
          setLoading(false);
          console.error('Registration Error:', error);
          alert('An error occurred during registration. Please try again later.');
        });
    } else {
      alert("Please fill in all fields and make sure passwords match");
    }
  };

  return (
    <div className='register'>
      <h1>Register</h1>
      <input type="text" name="name" value={user.name} placeholder='Your Name' onChange={handleChange} />
      <input type="email" name="email" value={user.email} placeholder="Your Email" onChange={handleChange} />
      <input type="password" name="password" value={user.password} placeholder="Create Password" onChange={handleChange} />
      <input type="password" name="reEnterpassword" value={user.reEnterpassword} placeholder="Re-enter Password" onChange={handleChange} />
      <div className='button' onClick={register}>
        {loading ? "Registering..." : "Register"}
      </div>

      {successMessage && (
        <div className="success-message">{successMessage}</div>
      )}

      <div>
        or
      </div>

      <div className="button" onClick={() => navigate("/login")}>Login</div>
    </div>
  );
};
