import React from 'react'
import "./Homepage.css"
import bushes from "../Assets/bushes.jpg"

export const Homepage = ({setLoginUser, user }) => {
  return (
    <div className='body' >
    
      <div className="homepage" style={{ backgroundImage:`url(${bushes})`,backgroundRepeat:"no-repeat",backgroundSize:"cover" }}>
      <h1>Welcome home {user.name}</h1>
          <div className="button" onClick={() => setLoginUser({})} >Logout</div>
      </div>
   
      </div> 
  )
  
}

